<script setup>
    import { useRouter } from '#app';
    import { inject, nextTick, onBeforeMount, onBeforeUnmount, onMounted, watch } from 'vue';

    import { requestIdleCallback } from '@resn/gozer-misc';
    import { useOnScroll } from '@resn/gozer-vue';
    import { LOADER_CHAIN } from '@resn/gozer-vue/loading';
    import { gsap } from '@resn/gsap';
    import { ScrollTrigger } from '@resn/gsap/all';

    import { useThreePrerender } from '../ThreePrerender';
    import { PageState } from './pageState';
    import { useAppStore } from '~/stores/app';

    const props = defineProps({
        enterDuration: { default: 1 },
        leaveDuration: { default: 1 },
    });

    const app = useAppStore();
    const router = useRouter();
    const scroller = useOnScroll();

    const prerender = useThreePrerender();

    const { root: loaderContext } = inject(LOADER_CHAIN);

    if (!loaderContext)
        throw new Error(
            'No LoaderProvider component found. Wrap nested components in <LoaderProvider> element.'
        );

    let outDelay;
    let enterDelay;

    const setPageState = async (val) => {
        // console.log('🚀 ~ setPageState ~ val:', val);

        switch (val) {
            case PageState.Preload:
                loaderContext.start().then(onPageLoaded);
                break;

            case PageState.PreloadComplete:
                await nextTick();

                ScrollTrigger.refresh();

                prerender.prerenderAll().then(() => {
                    requestIdleCallback(() => {
                        app.setPageState(PageState.EnterStart);
                    });
                });

                break;

            case PageState.EnterStart:
                enterDelay = gsap.delayedCall(props.enterDuration, () => {
                    // ScrollTrigger.refresh();

                    app.setPageState(PageState.EnterComplete);
                });

                break;
            case PageState.EnterSt:
                await nextTick();

                break;

            default:
                break;
        }
    };

    // const routeEntered = async () => {
    // Wait for prev page to unmount and next page to mount
    // setTimeout(() => {
    //     app.setPageState(PageState.Preload);
    // }, 300);
    // };

    const onPageLoaded = () => {
        app.setPageState(PageState.PreloadComplete);
        // if (app.pageLoaded) {
        // } else {
        //     app.pageLoaded = true;
        // }
    };

    // Triggers before each route has changed
    const onBeforeRoute = async () => {
        enterDelay?.kill();
        outDelay?.kill();

        app.setPageState(PageState.LeaveStart);
        app.firstPageRoute = false;

        outDelay = await gsap.delayedCall(props.leaveDuration, () => {
            // scroller.scrollTo(0, { immediate: true, force: true });

            app.setPageState(PageState.LeaveComplete);
        });
    };

    onBeforeMount(() => {
        scroller.scroller.scrollTo(0, { immediate: true, force: true });
    });

    onMounted(() => {
        // routeEntered();
        app.setPageState(PageState.Preload);
    });

    watch(() => app.pageState, setPageState);

    const removeRouterFn = router.beforeEach(onBeforeRoute);
    // const removeRouterAfterFn = router.afterEach(routeEntered);

    onBeforeUnmount(() => {
        enterDelay?.kill();
        removeRouterFn();
        // removeRouterAfterFn();
    });
</script>

<template>
    <slot />
</template>

<style lang="scss" scoped>
    @import '@/styles/shared.scss';
</style>
